.documentEmpty {
  width: 100%;
  height: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
}