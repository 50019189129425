@use '@css/variables';
.wrapper {
  min-width: 250px;
  display: flex;
  align-items: center;
}
.wrapper_background {
  background: variables.$darkBlueTransparentColor;
  padding: 4px 32px 4px 13px;
  border-radius: 4px;
}
.nameWrapper {
  margin-left: 11px;
  display: flex;
  flex-direction: column;
}
.name {
  margin-bottom: 4px;
  font-size: 16px;
}
.professionWrapper {
  margin-top: 4px;
  display: flex;
}
.profession {
  margin-right: 15px;
  font-size: 11px;
  color: #ADA2C3;
}