@use '@css/variables';
.wrapper {
  padding: 15px 0;
  max-width: 604px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.textarea {
  margin-right: 8px;
  width: 100%;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  background: transparent;
}
.error {
  width: 635px;
  position: absolute;
  bottom: -18px;
  left: 0;
  color: variables.$fieldErrorTextColor;
}