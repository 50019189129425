@use '@css/variables';
.linkWrapper {
  margin-bottom: 59px;
  display: flex;
  width: 100%;
}
.requestButton {
  margin-bottom: 16px;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: rgb(254, 196, 12);
  padding: 0 19px;
  height: 48px;
  background: variables.$darkBlueTransparentColor;
  border-radius: 4px;
}
.title {
  font-size: 48px;
  text-transform: uppercase;
  color: #FFC50C;
}
.wrapperTag {
  margin-top: 16px;
  display: flex;
}
.tag {
  margin-right: 16px;
  width: 120px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  border-radius: 12px;
  background: #FFC50C;
  color: #070707;
  text-transform: uppercase;
}
.imgWrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  //height: 435px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.img {
  width: 625px;
  height: 435px;
  border-radius: 15px;
  border: 8px solid rgba(254, 254, 254, 0.10);
  order: 0;
}
.img:nth-child(2), .img:nth-child(3) {
  margin-top: 20px;
  order: 2;
}
.description {
  margin-top: 40px;
  margin-right: 20px;
  max-width: 680px;
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  order: 2;
}
.description_order {
  margin-top: 0;
  order: -1;
}

