@use '@css/variables';
.documentWrapper {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}
.img {
  width: 100px;
  min-width: 100px;
  height: 70px;
  min-height: 70px;
  border-radius: 5px;
}
.document {
  width: 100px !important;
  height: 70px !important;
  border-radius: 5px;
  overflow: hidden;
  :global(.react-pdf__Page__canvas) {
    width: 100px !important;
    height: 70px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
}
.closeButton {
  margin-left: 5px;
}
.closeIcon {
  width: 35px;
  height: 35px;
  color: #4B4F57;
}