@use '@css/variables';
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
}
.select,  {
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
  font-weight: 400;
  border-radius: 4px;
  transition: border .3s ease;
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
  :global(.MuiSvgIcon-root) {
    color: #fff;
    font-size: 34px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    right: 0px;
  }
  :global(.MuiList-root){
    background: variables.$darkBlueTransparentColor;
  }
  :global(.MuiSelect-select) {
    padding: 0 32px 0 14px !important;
  }
}
.itemLabel {
  display: flex;
  align-items: center;
  height: 32px;
}