@use '@css/variables';

.wrapper {
  margin-bottom: 25px;
  width: 100%;
  height: 72px;
  position: relative;
  background: variables.$darkBlueColor;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 36px;
  letter-spacing: 20px;
  text-transform: uppercase;
}

.link {
  position: absolute;
  right: 8px;
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3.1px;
  text-transform: uppercase;
}