@use '@css/variables';
.wrapper {
  width: 173px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
}
.arrowIcon {
  color: #fff;
  font-size: 22px;
}
.dateWrapper {
  margin: 0 auto;
}
.dateButton {
  width: 120px;
  display: flex;
  justify-content: center;
  padding: 0;
  color: #fff;
  font-size: 20px;
  font-family: 'Rubik', sans-serif;
}
.popover {
  top: 4px !important;
  background: variables.$darkBlueTransparentColor;
  width: 275px;
  border-radius: 4px;
  overflow: hidden;
}
.yearBlock {
  width: 100%;
  height: 39px;
  background: variables.$darkBlueColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yearWrapper {
  position: relative;
}
.yearButton {
  width: 36px;
  padding: 0;
  background: linear-gradient(137.90deg, rgb(25, 84, 235),rgb(0, 167, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border: none;
  display: flex;
  &::after {
    content: "";
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    border-top: 4px solid  #1954EBFF;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
}
.yearList {
  max-height: 100px;
  overflow-y: auto;
  top: 18px;
  transform: translateX(-50%);
  left: 50%;
  width: 80px;
  position: absolute;
  background: variables.$darkBlueColor;
}
.yearItem {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.yearCalendar {
  background: variables.$darkBlueColor;
}
.monthWrapper {
  padding: 8px 12px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.month {
  background: linear-gradient(137.90deg, rgb(25, 84, 235),rgb(0, 167, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 16px;
}
.monthButton {
  width: 24px;
  height: 25px;
}
.weekWrapper {
  padding: 6px 12px;
  width: 100%;
}
.weekContainer {
  width: 100%;
  height: 24px;
  background: variables.$darkBlueColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.week {
  width: 38px;
  text-align: center;
}
.week:nth-child(7n+1) {
  width: 30px;
}
.week:nth-child(7n) {
  width: 30px;
}
.dayWrapper {
  padding: 0 12px 6px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dayContainer {
  width: 38px;
  margin: 6px 0;
  background: variables.$darkBlueColor;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}
.dayWrapper .dayContainer:nth-child(7n+1) {
  width: 30px;
  .dayButtonWrapper {
    justify-content: flex-start;
  }
  .dayButtonWrapper_minSelected {
    margin-left: 0 !important;
  }
}
.dayWrapper .dayContainer:nth-child(7n) {
  width: 30px;
  .dayButtonWrapper {
    justify-content: flex-end;
  }
  .dayButtonWrapper_maxSelected {
    margin-right: 0 !important;
  }
}
.dayButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dayButtonWrapper_selected {
  background: #ADA2C3;
}
.dayButtonWrapper_maxSelected {
  margin-right: 7px;
  justify-content: flex-end;
}
.dayButtonWrapper_minSelected {
  margin-left: 7px;
  justify-content: flex-start;
}
.dayButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}
.day_active {
  background: variables.$buttonBlueLinearGradient;
}
.day_nowActive {
  background: #00A7FF;
}
.buttonDisable {
  cursor: auto;
}
.dayDisabled {
  color: #ADA2C3;
}