@use '@css/variables';
.wrapper {
  padding-right: 22px;
  background: transparent;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.textarea {
  min-height: 32px;
  padding: 3px 0;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  background: transparent;
  color: #4B4F57 !important;
  &::placeholder {
    color: #777777;
  }
}
.error {
  width: 635px;
  position: absolute;
  bottom: -18px;
  left: 0;
  color: variables.$fieldErrorTextColor;
}