@use '@css/variables';
.wrapper {
  flex-direction: row;
}
.title {
  font-size: 32px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}
.buttonWrapper {
  display: flex;
}
.tableWrapper {
  margin-top: 25px;
  min-height: 400px;
  width: 785px;
  position: relative;
}
.tdDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.bodyTr {
  background: transparent;
  cursor: pointer;
  transition: background ease .3s;
  height: 56px;
  &:hover {
    background: rgba(32, 26, 86, 0.6);
    transition: background ease .3s;
  }
}