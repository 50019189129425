@use '@css/variables';
.title {
  margin-top: 34px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.16em;
}
.select, .input, .textarea {
  margin-top: 8px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 4px;
  background: variables.$darkBlueTransparentColor;
  border: 1px solid transparent;
  transition: border .3s ease;
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
  :global(.MuiSvgIcon-root) {
    color: #fff;
    font-size: 35px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  :global(.MuiList-root){
    background: variables.$darkBlueTransparentColor;
  }
  :global(.MuiSelect-select) {
    padding-right: 41px !important;
  }
}
.input, .textarea {
  padding: 14px;
}
.input_transparent {
  background: transparent;
  border: 1.5px solid variables.$darkBlueColor;
}
.item {
  background: variables.$darkBlueTransparentColor;
}
.textarea {
  height: 140px;
  height: 184px;
}
.input_error {
  border: 1px solid variables.$pinkBackgroundColor;
  transition: border .3s ease;
}
.error {
  margin-top: 3px;
  color: variables.$fieldErrorTextColor;
}
.link {
  color: #089EF0;
}
.iconWrapper {
  display: flex;
  align-items: center;
  height: 32px;
}
.icon {
  margin-right: 2px;
  font-size: 32px;
  height: 32px;
  width: 32px;
}