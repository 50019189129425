@use '@css/variables';
.inputWrapper {
  max-width: 424px;
  padding-right: 37px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
  position: relative;
  border-radius: 4px;
}
.input {
  padding-left: 15px;
  width: 100%;
  height: 56px;
  font-size: 18px;
}
.editButton {
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  position: absolute;
}
.telegramIcon {
  color: #fff;
}
