@use '@css/variables';
.buttonWrapper {
  width: 20px;
}
.avatarButton {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  overflow: hidden;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(99deg, #1954EB -14.26%, #00A7FF 109.27%);
}
.popover {
  border-radius: 5px;
  margin-top: 16px;
  :global(.MuiPaper-elevation) {
    background: variables.$darkBlueColor;
  }
}
.wrapper {
  padding: 7px 17px;
  width: 430px;
  max-height: 500px;
}
.title {
  margin: 15px 0 13px;
  font-size: 18px;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.margin {
  margin: 15px 0 13px;
}
.line {
  margin: 15px 15px 0;
  height: 1px;
  background: rgb(173, 162, 195);
}