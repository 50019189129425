@use '@css/variables';
.title {
  margin-left: auto;
  color: #ADA2C3;
  font-size: 16px;
}
.select,  {
  width: 174px;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 400;
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
  :global(.MuiSvgIcon-root) {
    color: #fff;
    font-size: 34px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    right: 0px;
  }
  :global(.MuiList-root){
    background: variables.$darkBlueTransparentColor;
  }
  :global(.MuiSelect-select) {
    padding: 0 32px 0 5px !important;
    div {
      display: flex;
      justify-content: center;
    }
  }
}
.item {
  background: variables.$darkBlueTransparentColor;
}
.itemLabel {
  display: flex;
  align-items: center;
  height: 32px;
}
.addButton {
  margin-left: 6px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  min-height: 32px;
  background: #20A93F;
  &:hover {
    background: #157c2c;
  }
}
.addIcon {
  color: #fff;
}