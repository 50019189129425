@use '@css/variables';
.nameWrapper {
  margin-top: 12px;
  padding: 0 7px 0 11px;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: rgb(233, 233, 233);
  color: #3E4954;
  text-transform: uppercase;
  position: relative;
}
.statusWrapper {
  top: 9px;
  right: 9px;
  position: absolute;
}
.status {
  margin-left: auto;
  width: 21px;
  height: 21px;
  border: 2px solid #DADADA;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkIcon {
  color: #509B64;
  font-size: 16px;
}
.priorityIcon {
  margin-left: 1px;
  font-size: 14px;
  color: #D80720;
}
.timeIcon {
  margin-left: auto;
  width: 25px;
  height: 25px;
  color: #DADADA;
}
.wrapper {
  margin-top: 12px;
  width: 100%;
  height: 152px;
  background: #E9E9E9FF;
  position: relative;
}
.wrapperButton {
  padding: 23px 18px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  border-radius: 2px !important;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
  &:hover {
    background: transparent;
  }
}
.starButton {
  top: 2px;
  left: 2px;
  position: absolute;
  padding: 0;
  z-index: 99;
}
.starIcon {
  color: rgb(229, 184, 21);
  font-size: 20px;
}
.avatarWrapper {
  position: relative;
}
.circleOnline {
  top: -2px;
  right: 15px;
  width: 18px;
  height: 18px;
  position: absolute;
  border: 2px solid rgb(233, 233, 233);
  background: rgb(188, 188, 188);
  border-radius: 50%;
}
.circleOnline_active {
  background: rgb(36, 159, 19);
}
.userContainer {
  margin-left: 40px;
  margin-bottom: auto;
}
.messageWrapper {
  display: flex;
  align-items: center;
  color: #BCBCBC;
}
.messageWrapper_active {
  color: #249F13;
}
.emailIcon {
  margin-right: 4px;
  font-size: 12px;
}
.message {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;
}
.personWrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: #3E4954;
}
.personIcon {
  margin-right: 4px;
  font-size: 20px;
  color: #3E4954;
}
.userName {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.startsUser {
  margin-top: 12px;
  display: flex;
  :global(.align-items_end-g) {
    align-items: flex-start;
    flex-direction: column;
  }
  :global(.align-items_end-g div:nth-child(2)) {
    margin: 5px 0 0 0;
  }
}
.coins {
  margin-left: 10px;
  padding: 3px 6px;
  min-width: 113px;
  height: 38px;
  color: #170B44;
  background: #D5DCEB;
  letter-spacing: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  div {
    margin-top: 3px;
  }
}
.lastMessage {
  margin-top: 22px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #959595;
}
.date {
  bottom: 13px;
  right: 10px;
  position: absolute;
  color: #959595;
}