@use '@css/variables';
.dotsButton {
  margin: auto 0 auto auto;
}
.dots {
  font-size: 30px;
  color: #0D0D0D;
}
.title {
  margin-top: 38px;
  color: #FEFEFE;
  font-size: 20px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 400;
}
.addButtonWrapper {
  width: 100%;
  display: flex;
}
.wrapper {
  width: 100%;
  min-height: 350px;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.documentWrapper {
  margin-top: 24px;
  width: 385px;
  border-radius: 19px;
  overflow: hidden;
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
}
.documentWrapper:nth-child(3n+2) {
  margin: 24px 22px 0;
}
.circle {
  top: 10px;
  right: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 999;
  background: #20A93F;
  border-radius: 50%;
}
.documentImage {
  width: 100%;
  min-height: 225px;
  height: 225px;
  background: #CECDCD;
  overflow: hidden;
  display: flex;
  position: relative;
  :global(.react-pdf__Page__canvas) {
    width: 225px !important;
    height: 517px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
}
.fileButton {
  margin: 25px auto 0;
  padding: 0;
  width: 225px;
  overflow: hidden;
  display: block;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: transparent;
  &:hover {
    background: transparent;
    .fileBg {
      opacity: 1;
      transition: opacity ease .3s;
    }
  }
}
.fileBg {
  transition: opacity ease .3s;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 999;
  background: variables.$darkBlueTransparentColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadIcon {
  width: 80px;
  height: 80px;
}
.description {
  width: 100%;
  height: 100%;
  padding: 20px 15px 20px 20px;
  display: flex;
  background: #FEFEFE;
}
.nameWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.name {
  color: #0D0D0D;
  font-size: 24px;
  line-height: 133.333%;
  letter-spacing: 2px;
  letter-spacing: 0.16em;
  text-transform: capitalize;
  font-weight: 400;
}
.min {
  display: flex;
  margin-top: auto;
  font-size: 16px;
  color: #777777;
}