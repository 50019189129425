@use '@css/variables';
.wrapper {
  margin-left: auto;
  padding: 14px 24px 12px;
  width: 470px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: variables.$darkBlueTransparentColor;
  border-radius: 12px;
  order: 1;
}
.title {
  width: 140px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgb(255, 197, 12);
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #070707;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addButton {
  margin-left: auto;
}
.addIcon {
  font-size: 40px;
}
.userWrapper {
  margin-top: 15px;
  display: flex;
}
.coinsWrapper {
  margin-right: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.coins {
  margin-right: 8px;
  font-size: 16px;
}
.currencyIcon {
  width: 33px;
  height: 20px;
}
