@use '@css/variables';
.wrapper {
  width: 100%;
  height: 104px;
  padding: 32px 45px 22px;
  display: flex;
  background: variables.$darkBlueColor;
  border: 1px solid rgb(5, 5, 5);
}
.buttonsWrapper {
  margin-left: auto;
  display: flex;
  height: 100%;
  align-items: center;
}
.acceptButton, .revokeButton {
  width: 120px;
  height: 48px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.acceptButton {
  margin-left: auto;
  margin-right: 16px;
  background: rgba(54, 98, 195, 0.6);
  &:hover {
    background: rgba(36, 66, 136, 0.6);
  }
}
.revokeButton {
  background: rgba(221, 47, 110, 0.6);
  &:hover {
    background: rgba(154, 37, 78, 0.6);
  }
}
.dotsButton {
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  margin-left: 16px;
  background: transparent;
  border-radius: 4px;
  &:hover {
    background: transparent;
  }
}
.dotsIcon {
  font-size: 34px;
  color: #D9D9D9;
}