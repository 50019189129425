@use '@css/variables';
.wrapper {
  width: 100%;
  display: flex;
}
.container {
  width: 25%;
  margin: 0 11px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
  border-radius: 6px;
}
.container:first-child {
  margin: 0 11px 0 0;
}
.container:last-child {
  margin: 0 0 0 11px;
}
