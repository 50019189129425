@use '@css/variables';
.container {
  margin: 25px 0 0 25px;
  :global(.label-title-g) {
    margin-top: 22px;
  }
}
.titleStrict {
  font-size: 18px;
}
.statusWrapper {
  display: flex;
  :global(.column-align_start-g:nth-child(1)) {
    margin-right: 28px;
  }
  :global(.column-align_start-g:nth-child(2)) {
    margin-left: 28px;
  }
}