@use '@css/variables';
.avatarWrapper {
  margin-left: auto;
  padding-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
}
.linkWrapper {
  width: 20px;
}
.avatarLink {
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  overflow: hidden;
}