@use '@css/variables';
.wrapper {
  padding-top: 10px;
  padding-bottom: 180px;
  margin: 0 11px;
  width: 25%;
  //background: rgba(59, 146, 253, 0.18);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper:first-child {
  margin: 0 11px 0 0;
}
.wrapper:last-child {
  margin: 0 0 0 11px;
}