.wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 100;
  transition: opacity ease .3s;
  opacity: 0;
  visibility: hidden;
}
.show {
  opacity: 1;
  visibility: visible;
  transition: opacity ease .3s;
}



