@use '@css/variables';
.arrowIcon {
  margin-right: 15px;
}
.tdDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.bodyTr {
  background: transparent;
  cursor: pointer;
  transition: background ease .3s;
  height: 56px;
  &:hover {
    background: rgba(32, 26, 86, 0.6);
    transition: background ease .3s;
  }
}
.currencyIcon {
  margin-left: 5px;
  width: 33px;
}
.timeIcon {
  font-size: 24px;
  color: #CCB22B;
}