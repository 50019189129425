@use '@css/variables';
.wrapper {
  margin-left: 24px;
}
.selectWrapper {
  margin-left: 24px;
  width: 212px;
  background: transparent;
  display: flex;
  align-items: center;
  &:hover {
    background: transparent;
  }
}
.filter {
  min-width: 66px;
  font-size: 16px;
  color: #ADA2C3;
}
.downIcon {
  margin-left: auto;
  color: #fff;
  font-size: 28px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.downIcon_up {
  transform: rotate(180deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.selectNameWrapper {
  width: 100%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectName {
  margin-left: 5px;
  font-size: 20px;
}
.popover {
  z-index: 999;
  .arrow {
    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  &[data-popper-placement*="bottom"] {
    top: 12px !important;
  }
  &[data-popper-placement*="bottom"] .arrow {
    top: -8px;
    right: 3px !important;
    left: auto !important;
    transform: none!important;
    &::before {
      border-width: 0 10px 8px 10px;
      border-color: transparent transparent variables.$darkBlueTransparentColor transparent;
    }
  }
  &[data-popper-placement*="top"] {
    bottom: 12px !important;
  }
  &[data-popper-placement*="top"] .arrow {
    bottom: -8px;
    right: 3px !important;
    left: auto !important;
    transform: none !important;
    &::before {
      border-width: 8px 10px 0 10px;
      border-color: variables.$popoverBackground transparent transparent transparent;
    }
  }
}
.popoverContainer {
  padding: 5px 0;
  background: variables.$darkBlueTransparentColor;
  width: 212px;
  max-height: 216px;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.menuItem {
  padding: 9px 12px;
}
.avatarWrapper {
  padding-right: 8px;
  width: 27px;
  display: flex;
  justify-content: center;
}
.avatarContainer {
  width: 5px;
}
.avatar {
  height: 14px;
  min-width: 14px;
  border-radius: 50%;
}
.bigAvatar {
  height: 27px;
  min-width: 27px;
  border-radius: 50%;
}
.name {
  margin-left: 9px;
  font-size: 18px;
}