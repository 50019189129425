@use '@css/variables';
.buttonWrapper {
  margin: auto 0 auto 8px;
  display: flex;
}
.dotsButton {
  visibility: hidden;
}
.dotsButton_show {
  visibility: visible;
}
.dots {
  color: #fff;
  font-size: 36px;
}
.wrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
}
.follow, .following {
  margin: auto 0 auto auto;
  width: 152px;
  height: 40px;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  overflow: hidden;
}
.follow {
  background: variables.$buttonBlueLinearGradient;
  color: #fff;
}
.follow::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: variables.$buttonBlueHoverLinearGradient;
  opacity: 0;
  transition: opacity ease .3s;
}
.follow:hover::before {
  opacity: 1;
  transition: opacity ease-out .3s;
}
.following {
  background: #D5D5D5;
  color: #0D0D0D !important;
}
.following:hover {
  background: #D5D5D5;
}
