@use '@css/variables';
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #E9E9E9;
  position: relative;
}
.chat {
  padding-top: 14px;
}
.line {
  width: 100%;
  height: 1px;
  background: linear-gradient(137.90deg, rgb(25, 84, 235) -17.982%,rgb(0, 167, 255) 117.266%)
}
.conversation {
  margin: 8px 0;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #3E4954;
}
.text {
  margin-top: 120px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #1e2428;
}