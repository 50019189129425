@use '@css/variables';
.wrapper {
  margin-bottom: 25px;
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background: variables.$darkBlueColor;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
  min-height: 40px;
  position: relative;
  border-radius: 5px;
  font-family: 'Rubik', sans-serif;
}