@use '@css/variables';
.test {
  width: 50px;
  height: 50px;
  background: red;
}
.button {
  margin-right: 5px;
}
.imgWrapper {
  margin: 15px 0 5px 0;
  display: flex;
  align-items: center;
  order: -1;
  width: 100%;
}
.img {
  height: 80px;
}
.inputWrapper {
  margin-left: 10px;
  padding-right: 35px;
  height: 56px;
  width: 705px;
  display: flex;
  background: variables.$darkBlueTransparentColor;
  border-radius: 30px;
  overflow: hidden;
}
.input {
  width: 635px;
  height: 56px;
  padding: 0 23px 0 23px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  background: transparent;
}
.icon {
  cursor: pointer;
  margin-left: 15px;
}