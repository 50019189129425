@use '@css/variables';
.coinsWrapper {
  margin: 24px auto 0;
  padding: 19px;
  width: 176px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dd2f6e;
  border-radius: 28px;
  font-size: 18px;
  color: #DD2F6E;
  font-weight: 400;
  letter-spacing: 0.9px;
}
.coinsWrapper_small {
  margin: 31px auto 0;
  width: 154px;
}
.currencyIcon {
  width: 33px;
  height: 20px;
}