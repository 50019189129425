@use '@css/variables';
.input {
  padding-left: 15px;
  width: 100%;
  height: 36px;
  font-size: 18px;
}
.error {
  left: 15px;
  bottom: 5px;
  position: absolute;
  color: variables.$fieldErrorTextColor;
}
