@use '@css/variables';
.avatarLink {
  min-width: 64px;
  width: 64px;
  height: 64px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
.avatar {
  width: 100%;
  height: 100%;
}


