@use '@css/variables';
.linkWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.link {
  margin-right: 48px;
  font-size: 24px;
  line-height: 133.333%;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  position: relative;
  .linkText {
    line-height: 133.333%;
    opacity: 1;
    transition: opacity ease .3s;
  }
  .linkGradient {
    top: 0;
    left: 0;
    line-height: 133.333%;
    position: absolute;
    opacity: 0;
    background: variables.$linkActiveGradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: opacity ease .5s;
    &::after {
      content: "";
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 100%;
      height: 2px;
      opacity: 0;
      background: variables.$linkActiveGradient;
      transition: opacity ease .5s;
    }
    &:hover::after {
      opacity: 1;
      transition: opacity ease .5s;
    }
  }
  &:hover .linkText {
    opacity: 0;
    transition: opacity ease .5s;
  }
  &:hover .linkGradient {
    opacity: 1;
    transition: opacity ease .5s;
  }
}
.link_active {
  .linkText {
    opacity: 0;
  }
  .linkGradient {
    opacity: 1;
    &::after {
      opacity: 1;
    }
  }
}
.searchWrapper {
  margin-top: 45px;
  width: 100%;
  display: flex;
  align-items: center;
}
.tableWrapper {
  margin-top: 50px;
  width: 100%;
  min-height: 400px;
  position: relative;
}