@use '@css/variables';
.wrapper {
  margin-left: auto;
  padding: 14px 14px 28px 22px;
  width: 392px;
  display: flex;
  flex-direction: column;
  background: variables.$darkBlueTransparentColor;
  border-radius: 4px;
  position: relative;
}
.asideTitle {
  font-size: 20px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  text-align: left;
}
.closeButton {
  top: 6px;
  right: 6px;
  position: absolute;
}
.closeIcon {
  color: #fff;
  width: 22px;
  height: 22px;
}
.arrowIcon {
  margin-top: 29px;
}
.amount {
  margin-top: 8px;
  font-size: 40px;
}
.currencyIcon {
  margin-left: 10px;
  width: 65px;
  height: 35px;
}
.title {
  margin-top: 20px;
  color: #ADA2C3;
}
.text {
  margin-top: 6px;
  font-size: 22px;
}
.statusWrapper {
  margin-top: 6px;
  display: flex;
}
.status_success, .status_hold, .status_error {
  margin-left: 5px;
  font-size: 22px;
}
.status_success {
  color: #20A93F;
}
.timeIcon {
  font-size: 24px;
  color: #CCB22B;
}
.status_hold {
  color: #CCB22B;
}
.status_error {
  color: #B02F2F;
}
.link {
  margin-top: 6px;
  font-size: 22px;
  color: #059DF0;
}