@use '@css/variables';
.searchWrapper {
  width: 288px;
  height: 40px;
  position: relative;
}
.search {
  width: 100%;
  height: 100%;
  padding: 0 30px 0 10px;
  border-radius: 4px;
  background: variables.$darkBlueTransparentColor;
  &::placeholder {
    font-style: italic;
  }
}
.searchButton {
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  position: absolute;
}
.searchIcon {
  font-size: 27px;
  color: #ADA2C3;
}
.thDiv {
  height: 100%;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thButton {
  display: flex;
  font-size: 22px;
  justify-content: center;
  align-items: center
}
.arrow {
  margin-left: 4px;
  font-size: 25px;
}
.arrow_up {
  transform: rotateX(180deg);
}
.tbody {
  margin-top: 8px;
}