@use '@css/variables';
.fileButton {
  margin-right: 5px;
}
.fileIcon {
  font-size: 28px;
  color: #777777;
}
.error {
  width: 635px;
  position: absolute;
  bottom: -18px;
  left: 0;
  color: variables.$fieldErrorTextColor;
}
.documentWrapper {
  display: flex;
  align-items: center;
}
.img {
  width: 100px;
  min-width: 100px;
  height: 88px;
  min-height: 88px;
  border-radius: 5px;
}
.document {
  width: 100px !important;
  height: 88px !important;
  border-radius: 5px;
  overflow: hidden;
  :global(.react-pdf__Page__canvas) {
    width: 100px !important;
    height: 88px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
}
.closeButton {
  margin-left: 5px;
}
.closeIcon {
  width: 35px;
  height: 35px;
  color: #4B4F57;
}