@use '@css/variables';
.wrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
}
.container {
  margin-left: 10px;
  padding: 0 18px;
  min-height: 64px;
  width: 705px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 30px;
  background: variables.$darkBlueTransparentColor;
}
.textareaWrapper {
  max-width: 100%;
}
.textarea {
  margin-right: 0;
}
.img {
  margin: 15px 0 5px 0;
  width: 90px;
  height: 90px;
}