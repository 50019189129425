@use '@css/variables';
.wrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
}
.form {
  margin-left: 10px;
  padding: 0 18px;
  min-height: 64px;
  width: 705px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 30px;
  background: variables.$darkBlueTransparentColor;
}
