@use '@css/variables';
.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.fileButton {
  margin: 0 auto 0;
  width: 120px;
  height: 120px;
  background: #FFF;
  border-radius: 4px;
  padding: 4px;
  &:hover {
    background: #FFF;
  }
}
.fileBorder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 0.6px dashed rgba(60, 50, 80, 0.50);
}
.fileIcon {
  margin: 36px auto  0 auto;
}
.fileText {
  margin-top: 5px;
  color: #7D7D7D;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.06em;
}
.documentWrapper {
  margin: 0 auto;
  position: relative;
}
.document {
  width: 255px !important;
  height: 200px !important;
  border-radius: 5px;
  overflow: hidden;
  :global(.react-pdf__Page__canvas) {
    width: 255px !important;
    height: 317px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
}
.document_small {
  width: 120px !important;
  height: 120px !important;
  :global(.react-pdf__Page__canvas) {
    width: 120px !important;
    height: 120px !important;
  }
}
.button {
  top: 50%;
  transform: translateY(-50%);
  right: -60px;
  position: absolute;
}
.icon {
  width: 35px;
  height: 35px;
}
.error {
  bottom: -20px;
  position: absolute;
  width: 100%;
  margin-top: 7px;
  text-align: center;
  color: variables.$fieldErrorTextColor;
}