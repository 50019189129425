@use '@css/variables';
.container {
  position: relative;
  padding: 24px 12px;
  width: 100%;
  min-height: 592px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #241756;
}
.title, .online {
  font-size: 20px;
}
.title {
  font-weight: 500;
  color: #fff;
}
.online {
  color: #01D5B5;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
}
.avatar {
  margin: 32px auto 0;
  width: 176px;
  height: 176px;
  border-radius: 50%;
}
.name {
  margin-top: 24px;
  font-size: 26px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  height: fit-content;
}
.profession {
  margin-top: 8px;
  color: #557EF8;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.balanceWrapper {
  margin-top: 24px;
}
.starsWrapper {
  margin: 24px auto 0;
  padding: 12px 24px;
  width: 176px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 28px;
  background: #DD2F6E;
}
.messageWrapper {
  margin: 17px auto 0;
  display: flex;
  align-items: center;
  color: #FEFEFE;
  font-size: 16px;
  letter-spacing: 0.64px;
  font-weight: 500;
}
.messageWrapper span {
  margin: 0 0 2px 3px;
}
.follow, .following {
  margin: 32px auto 0;
  width: 248px;
  height: 56px;
  padding: 8px 24px;
  border-radius: 4px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.follow {
  background: variables.$buttonBlueLinearGradient;
  color: #fff;
}
.follow::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: variables.$buttonBlueHoverLinearGradient;
  opacity: 0;
  transition: opacity ease .3s;
}
.follow:hover::before {
  opacity: 1;
  transition: opacity ease-out .3s;
}
.following {
  background: #D5D5D5;
  color: #0D0D0D !important;
}
.date {
  text-align: center;
  margin-top: auto;
  font-size: 14px;
  font-weight: 400;
  color: #6654DB;
}