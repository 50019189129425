.task, .progress {
  margin-top: 24px;
  padding: 24px 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: #271156ab;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
.task {
  margin-right: 21px;
}
.progress {
  margin-left: 21px;
}