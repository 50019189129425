@use '@css/variables';
.wrapper {
  width: 435px;
  max-height: 990px;
  background: #E9E9E9;
  padding: 13px;
}
.accordion {
  margin-top: 14px !important;
  background: transparent;
  box-shadow: none;
  border: none;
  overflow: hidden;
  border-radius: 2px !important;
}
.summary {
  padding: 0 0px 0 8px;
  width: 100%;
  height: 24px;
  min-height: 24px !important;
  max-height: 24px !important;
  background: rgb(188, 188, 188);
  box-shadow: none;
  border: none;
  border-radius: 2px !important;
  overflow: hidden;
  :global(.MuiAccordionSummary-content) {
    margin: 0;
  }
  :global(.MuiSvgIcon-root) {
    font-size: 30px;
  }
}
.accordionTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 6.22px;
  color: #454545;
  text-transform: uppercase;
}
.detailsWrapper {
  background: transparent;
  padding: 0;
}