@use '@css/variables';
.wrapper {
  margin-top: 40px;
  margin-left: auto;
  padding: 20px;
  width: 470px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
  border-radius: 12px;
  position: relative;
  order: 3;
}
.title {
  font-size: 24px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}
.linkWrapper {
  margin-top: 17px;
  width: 100%;
  display: flex;
  height: 56px;
  border-radius: 4px;
  overflow: hidden;
}
.link {
  padding: 0 12px;
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
}
.linkBorder {
  width: 8px;
  height: 100%;
  background: linear-gradient(137.90deg, rgb(25, 84, 235) -17.919%,rgb(0, 167, 255) 117.132%);
}
