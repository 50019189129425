@use '@css/variables';
.buttonWrapper {
  margin-top: auto;
  width: 100%;
}
.button {
  color: #fff;
  margin: 14px auto 10px auto;
  display: flex;
  font-size: 16px;
  letter-spacing: 0.14px;
  text-transform: none;
  min-height: 36px;
  span {
    margin: 0 3px 2px 0;
  }
}
.button_bottom {
  margin: 14px auto 0 auto;
}