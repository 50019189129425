html, body, body div, span, a, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav,
section, time, mark, audio, video, details, summary, input, textarea, hr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  line-height: 1;
}
:not(output):-moz-ui-invalid {
  box-shadow: none;
}
html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  min-width: 320px;
  height: 100%;
}
body {
  position: relative;
  min-width: 320px;
  height: 100%;
  min-height: 100vh;
  text-align: left;
}
/* Add the correct display in Edge, IE 10+, and Firefox.*/
article, aside, figcaption, figure, footer, header, nav, main, hgroup, section, details, summary {
  display: block;
}
button {
  padding: 0;
}
/* Remove the border on images inside links in IE 10. */
img {
  max-width: 100%;
  display: block;
  border-style: none;
}

object, embed {
  max-width: 100%;
  vertical-align: bottom;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

hr {
  border: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

input, textarea {
  color: #333;
  outline: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: 100%;
  -webkit-text-decoration-skip: objects;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
}

span {
  white-space: pre-line;
}

pre {
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}


address, caption, cite, code, dfn, em, th, var {
  font-weight: normal;
  font-style: normal;
}

/* Add the correct font size in all browsers.*/
small {
  font-size: 80%;
}

/* Add the correct font weight in Chrome, Edge, and Safari.*/
b, strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/*  Prevent `sub` and `sup` elements from affecting the line height in all browsers.
 Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, kbd, samp {
  font-family: monospace, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* Add the correct vertical alignment in Chrome, Firefox, and Opera. */
progress {
  vertical-align: baseline;
}

/*
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

/* Correct the padding in Firefox. */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

button, input, optgroup, select, textarea {
  outline: none;
}

input, button {
  background: transparent;
  border: none;
}

input[type=file] {
  display: none;
  visibility: hidden;
}

label, input[type=button], input[type=submit], input[type=file], button {
  cursor: pointer;
}

input, select {
  vertical-align: middle;
}
/* Remove the inheritance of text transform in Edge, Firefox, and IE.
   Remove the inheritance of text transform in Firefox.  */
button, select {
  text-transform: none;
}

button:focus {
  outline: none;
  border: none;
}

/*Correct the inability to style clickable types in iOS and Safari.*/
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* Remove the inner border and padding in Firefox.*/
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus,
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
[tabindex="-1"]:focus {
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
