.starsWrapper {
  padding: 0 6px;
  width: 88px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: #DD2F6E;
  color: #fff;
}
.starsWrapper_big {
  height: 20px;
}
.reviews {
  font-size: 13px;
  margin-left: 15px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #ADA2C3;
}