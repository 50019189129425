@use '@css/variables';
.requestWrapper {
  margin-left: auto;
  padding: 33px 40px;
  width: 440px;
  height: 435px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
  border-radius: 12px;
}
.timecoinWrapper {
  width: 120px;
  height: 120px;
  background: variables.$pinkBackgroundColor;
  border-radius: 50%;
}
.timecoin {
  margin-top: 23px;
  font-size: 55px;
  letter-spacing: 1.38px;
  text-align: center;
}
.timecoin_small {
  margin-top: 35px;
  font-size: 35px;
}
.timecoinName {
  margin-top: 3px;
  font-size: 13px;
  text-align: center;
}
.timeDescription {
  margin-top: 16px;
  font-size: 16px;
  letter-spacing: 0.14px;
}
.profileWrapper {
  margin-top: 40px;
  padding: 20px 16px;
  width: 296px;
  height: 96px;
  display: flex;
  border-radius: 8px;
  background: variables.$darkBlueColor
}
.firstNameWrapper {
  margin-left: 16px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
}
.firstName {
  margin-bottom: auto;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.requestDate {
  margin-top: auto;
  color: rgb(153, 142, 180);
}

