@use '@css/variables';
@import "@buttons/BlueButton/BlueButton.module.scss";
@import "@fields/FormField/FormField.module.scss";
.buttonWrapper {
  margin-top: 8px;
  display: flex;
}
.buttonTitle {
  z-index: 2;
}
.descriptionWrapper {
  margin: auto 0 0 14px;
  padding-bottom: 2px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.descriptionWrapper span {
  display: block;
  margin-top: 3px;
  color: #B7B7B7;
  font-size: 12px;
  line-height: 16px;
}
.img {
  margin: auto 0 auto 20px;
  width: 46px;
  height: 46px;
  border-radius: 6px;
  object-fit: cover;
}
.icon {
  margin: auto 0 auto 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}