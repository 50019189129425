@use '@css/variables';
.buttonWrapper {
  margin: auto 0 auto 8px;
  display: flex;
}
.dots {
  color: #fff;
  font-size: 36px;
}
.popover {
  z-index: 999;
  right: -23px !important;
  .arrow {
    position: absolute;
    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  &[data-popper-placement*="bottom"] {
    top: 4px !important;
  }
  &[data-popper-placement*="bottom"] .arrow {
    top: -5px;
    left: -23px !important;
    &::before {
      border-width: 0 13px 5px 13px;
      border-color: transparent transparent variables.$popoverBackground transparent;
    }
  }
  &[data-popper-placement*="top"] {
    bottom: 4px !important;
  }
  &[data-popper-placement*="top"] .arrow {
    bottom: -5px;
    left: -23px !important;
    &::before {
      border-width: 5px 13px 0 13px;
      border-color: variables.$popoverBackground transparent transparent transparent;
    }
  }
}
.container {
  border-radius: 8px;
  padding: 10px 0;
  min-width: 120px;
  height: auto;
  background: variables.$popoverBackground;
}
.item {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background ease .3s;
  &:hover {
    background: variables.$popoverHoverBackground;
    transition: background ease .3s;
  }
}
.text {
  margin-left: 8px;
  color: #FEFEFE;
  font-size: 16px;
}