.wrapper {
  padding: 12px 0 0 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.title span {
  margin-left: 22px;
  font-size: 18px;
  font-weight: 400;
}