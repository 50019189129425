@use '@css/variables';
.popover {
  border-radius: 5px;
  margin-top: 16px;
  :global(.MuiPaper-elevation) {
    background: #2e2169;
  }
}
.wrapper {
  padding: 20px;
  width: 430px;
  max-height: 500px;
}