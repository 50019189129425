@use '@css/variables';
.rankButton {
  margin-left: 30px;
  width: 104px;
  height: 48px;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  background: rgb(38, 116, 67);
  span {
    font-size: 16px;
    font-weight: 600;
    margin-right: 0px;
  }
  &:hover {
    background: rgb(28, 93, 53);
  }
}
.statusButton {
  margin-left: 16px;
  width: 125px;
}
.popover {
  z-index: 999;
  left: 47px !important;
  .arrow {
    position: absolute;
    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  &[data-popper-placement*="bottom"] {
    top: 16px !important;
  }
  &[data-popper-placement*="bottom"] .arrow {
    top: -5px;
    left: -19px !important;
    &::before {
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent rgba(38, 116, 67, 0.8) transparent;
    }
  }
  &[data-popper-placement*="top"] {
    bottom: 16px !important;
  }
  &[data-popper-placement*="top"] .arrow {
    bottom: -5px;
    left: -19px !important;
    &::before {
      border-width: 5px 13px 0 13px;
      border-color: rgba(38, 116, 67, 0.8) transparent transparent transparent;
    }
  }
}
.downIcon {
  color: #fff;
  font-size: 28px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.downIcon_up {
  transform: rotate(180deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.popoverContainer {
  width: 208px;
  height: 216px;
  background: rgba(38, 116, 67, 0.8);
  border-radius: 4px;
  padding: 30px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.radioInput {
  :global(.MuiTypography-root ) {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
}
.radioWrapper {
  margin-top: 28px;
  display: flex;
  align-items: center;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.radioWrapper:nth-child(1) {
  margin-top: 0;
}
.radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radioButton {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  background: variables.$darkBlueTransparentColor;
  border-radius: 50%;
  position: relative;
  &::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: rgb(254, 196, 12);
    opacity: 0;
    transition: opacity ease .3s;
  }
}
.radio:checked ~ .radioButton::after {
  opacity: 1;
  transition: opacity ease .3s;
}
.fixed {
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fixed_show {
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
}