@use '@css/variables';
.wrapper {
  margin-top: 30px;
  padding: 0 12px 0 16px;
  width: 318px;
  height: 34px;
  background: #FEFEFE;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
.input {
  margin: 0 15px 0 0;
  height: 100%;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  color: #4B4F57;
  &::placeholder {
    color: #777777;
  }
}
.submitButton {
  padding: 0;
  height: 24px;
  min-height: 24px;
}
.telegramIcon {
  min-width: 24px;
  min-height: 24px;
}
