@use '@css/variables';
.addButton {
  margin-left: auto;
  & :global(.MuiButtonBase-root) {
    width: 200px;
    height: 40px;
    font-size: 20px;
  }
}
.addIcon  {
  font-size: 26px;
}
.modal {
  :global(.MuiDialog-container) {
    width: 100%;
  }
  :global(.MuiDialog-paper) {
    max-width: 625px;
    width: 100%;
    background: transparent;
  }
}
.form {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2000;
}
.container {
  padding: 35px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background: #321a5e;
}
.closeButton {
  top: 11px;
  right: 11px;
  position: absolute;
}
.closeIcon {
  width: 26px;
  height: 26px;
}
.title {
  color: #FEFEFE;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.button {
  margin: 32px auto 0 auto;
  width: 100%;
  max-width: 285px;
  height: 32px;
  color: #FEFEFE;
  background: #91225B;
  transition: background ease .3s;
  &:hover {
    background: #6c1a44;
    transition: background ease .3s;
  }
}