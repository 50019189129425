//Background

$darkBlueTransparentColor: rgba(30, 14, 63, 0.60);
$darkBlueColor: #1E0E3F; //rgb(30, 14, 63)
$pinkBackgroundColor: #DD2F6E;

$popoverBackground: rgba(30, 14, 63, 0.80);
$popoverHoverBackground: rgba(55, 19, 129, 0.8);

$fieldErrorTextColor: #FD0B0B;

// Button
$buttonBlueLinearGradient: linear-gradient(138deg, #1954EB 0%, #00A7FF 100%);
$buttonBlueHoverLinearGradient: linear-gradient(138deg, #1641af 0%, #0173b2 100%);

$buttonPinkLinearGradient: linear-gradient(136deg, #DE0071 0%, #F500A7 100%);
$buttonPinkHoverLinearGradient: linear-gradient(136deg, #9b004c 0%, #af007a 100%);

// Links
$linkActiveGradient: linear-gradient(99deg, #1954EB -14.26%, #00A7FF 109.27%);

