@use '@css/variables';
.dotsButton {
  margin: auto 0 auto auto;
}
.dots {
  color: #fff;
  font-size: 35px;
}
.wrapper {
  margin-bottom: 48px;
  width: 100%;
  border-radius: 20px;
  padding: 30px 20px 48px 24px;
  background: variables.$darkBlueTransparentColor;
}
.imageWrapper {
  margin-top: 29px;
  display: flex;
}
.image {
  width: 480px;
  height: 228px;
  border-radius: 15px;
  border: 8px solid rgba(254, 254, 254, 0.10);
}
.titleWrapper {
  margin: 24px 0 0 31px;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.description {
  margin-top: 16px;
  font-weight: 300;
  font-size: 16px;
}
.commentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

