@use '@css/variables';
.form {
  width: 900px;
}
.title {
  margin-top: 48px;
  font-size: 40px;
}
.buttonWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  :global(.MuiButtonBase-root) {
    height: 72px;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

