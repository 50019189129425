@use '@css/variables';
.searchWrapper {
  width: 377px;
  height: 48px;
  position: relative;
}
.searchInput {
  padding: 0 35px 0 11px;
  width: 100%;
  height: 100%;
  background: linear-gradient(86.53deg, rgb(40, 16, 90) 0.965%,rgb(49, 23, 104) 99.963%);
  border-radius: 4px;
  &::placeholder {
    font-style: italic;
    color: #ADA2C3;
  }
}
.searchIcon {
  transform: translateY(-50%);
  right: 7px;
  position: absolute;
  font-size: 27px;
  color: #ADA2C3;
  padding: 0;
  top: 50%;
}
.closeButton {
  transform: translateY(-50%);
  right: 8px;
  position: absolute;
  padding: 0;
  top: 50%;
}
.closeIcon {
  width: 24px;
  height: 24px;
}
.popper {
  position: relative;
  padding: 10px 0;
  max-height: 265px;
  overflow-y: auto;
  background: variables.$popoverBackground;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  :global(.MuiCircularProgress-root) {
    width: 22px !important;
    height: 22px !important;
  }
}
.item {
  padding: 6px 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background ease .3s;
  &:hover {
    background: variables.$popoverHoverBackground;
    transition: background ease .3s;
  }
}
.text {
  padding: 4px 10px;
  color: #FEFEFE;
  font-size: 16px;
}

