@use '@css/variables';
.wrapper {
  height: 25px;
  margin-top: 8px;
  cursor: pointer;
  font-size: 22px;
  color: #059DF0;
  display: flex;
}
.copied {
  margin-left: auto;
  font-size: 18px;
  color: #fff;
}
.copyIcon {
  margin-left: auto;
}