body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #311768;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
}
#root {
  background: url(../images/bgs/header.png) center top no-repeat #311768;
}
button, a, input, select, textarea {
  color: #fff;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
}
input, select, textarea {
  &::placeholder {
    color: #ADA2C3;
    font-family: 'Rubik', sans-serif;
  }
}
table, thead, tr, th, tbody, td {
  height: 100%;
}
textarea {
  resize: none;
}
.hide-g {
  display: none;
}
.page-container-g {
  margin: 60px auto 50px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.z-index-5-g {
  z-index: 5;
}
.position-relative-g {
  position: relative;
}
.height_max-g {
  height: 100%;
}
.width_max-g {
  width: 100%;
}
.flex-g {
  display: flex;
}
.direction_column-g {
  display: flex;
  flex-direction: column;
}
.direction_column-max-g {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.direction_row-g {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.flex-center-g {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-items_start-g {
  display: flex;
  align-items: flex-start;
}
.align-items_end-g {
  display: flex;
  align-items: flex-end;
}
.align-items_center-g {
  display: flex;
  align-items: center;
}
.align-self_start-g {
  display: flex;
  align-self: flex-start
}
.space-between-g {
  display: flex;
  justify-content: space-between;
}
.column-align_start-g {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.margin-left_auto-g {
  margin-left: auto;
}
// Material
.MuiPopover-paper {
  background: transparent;
}
.MuiTouchRipple-root { // show animations buttons
  z-index: 99;
}
.MuiModal-root {
  display: flex;
  overflow: auto;
}
.MuiIconButton-root {
  padding: 0;
  color: #fff;
}
// Global
// label-title-g field-input-g FormField