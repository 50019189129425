.wrapper {
  margin-top: 35px;
  padding: 15px 8px;
  width: 100%;
  border-radius: 10px;
  background-color: #241756;
}
.quantity {
  font-size: 18px;
  font-weight: 400;
}
.title {
  margin-left: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.selectedTitle {
  text-decoration: underline;
  color: #059DF0;
}
.avatarWrapper {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
}
.avatar {
  margin: 9px 4px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.button {
  margin: 0 auto;
  display: flex;
  font-size: 12px;
  color: #557EF8;
  font-weight: 500;
}
.button_disabled {
  color: #557EF8 !important;
}


