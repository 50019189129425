.wrapper {
  margin-bottom: 50px;
  padding: 21px 0 0 70px;
  width: 100%;
  position: relative;
  :global(.label-title-g) {
    margin-top: 24px;
    font-size: 13px;
    letter-spacing: 0.18em;
  }
}
.title {
  font-size: 24px;
  font-weight: 500;
  color: #089EF0;
  text-transform: uppercase;
}
.form {
  margin-top: 32px;
}


