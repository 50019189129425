@use '@css/variables';
.wrapper {
  margin-top: auto;
  width: 100%;
  min-height: 170px;
  max-height: 500px;
  padding: 30px 57px 30px 46px;
  background: #F7F7F7;
}
.messageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sendWrapper  {
  display: flex;
  align-items: flex-start;
}
.submitButton {
  padding: 0;
}
.telegramIcon {
  min-width: 32px;
  min-height: 32px;
}
.smileButton {
  margin: -2px 20px 0 auto;
  padding: 0;
}
.smileIcon {
  min-width: 37px;
  min-height: 37px;
}