@use '@css/variables';
.avatarWrapper {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}
.date {
  margin-top: 6px;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #0D0D0D;
}