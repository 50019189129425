@use '@css/variables';
.wrapper {
  min-width: 208px;
  max-width: 208px;
  height: 56px;
  background: variables.$darkBlueTransparentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.starIcon {
  color: #0F6ADF;
  font-size: 20px;
}