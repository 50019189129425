@use '@css/variables';
.button {
  width: 50%;
  height: 32px;
  background: #E9E9E9;
  position: relative;
  overflow: hidden;
  z-index: 0;
  span {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    color: #454545;
    letter-spacing: 6.22px;
    text-transform: uppercase;
    transition: color ease .3s;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(137.90deg, rgb(25, 84, 235) -13.76%,rgb(0, 167, 255) 108.203%);
    opacity: 0;
    transition: opacity ease .3s;
  }
}
.button:nth-child(1) {
  border-radius: 2px 0 0 2px;
  &::after {
    left: 0;
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #02A1FE;
    opacity: 1;
  }
}
.button:nth-child(2) {
  border-radius: 0 2px 2px 0;
  &::after {
    right: 0;
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #02A1FE;
    opacity: 1;
    transition: opacity ease .3s;
  }
}
.button_active, .button:hover {
  background: #E9E9E9;
  span {
    color: #fff;
    transition: color ease .3s;
  }
  &::after {
    opacity: 0;
    transition: opacity ease .3s;
  }
  &::before {
    opacity: 1;
    transition: opacity ease .3s;
  }
}