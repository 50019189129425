@use '@css/variables';
.button {
  margin-right: 17px;
  width: 120px;
  height: 40px;
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  background: variables.$darkBlueTransparentColor;
  border-radius: 4px;
  border: 2px solid #0D7DF5 !important;
  text-transform: capitalize;
  &:hover {
    background: #0D7DF5;
  }
}
.button_active {
  background: #0D7DF5;
}
.thDiv {
  height: 100%;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thButton {
  display: flex;
  font-size: 22px;
  justify-content: center;
  align-items: center
}
.arrow {
  margin-left: 4px;
  font-size: 25px;
}
.arrow_up {
  transform: rotateX(180deg);
}