@use '@css/variables';
.button {
  width: 248px;
  height: 56px;
  font-size: 18px;
  color: #fff;
  background: variables.$buttonPinkLinearGradient;
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: 0.035em;
  transition: opacity ease .3s;
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    background: variables.$buttonPinkHoverLinearGradient;
    opacity: 0;
    transition: opacity ease .3s;
  }
  &:hover::before {
    opacity: 1;
    transition: opacity ease .3s;
  }
}





