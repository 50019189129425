@use '@css/variables';
.formTd {
  padding-top: 32px;
  padding-right: 11px;
}
.td {
  padding: 32px 11px 0;
}
.startsTd {
  padding-top: 32px;
  padding-left: 11px;
}
.tdDiv {
  min-width: 124px;
  max-width: 124px;
  height: 56px;
  background: variables.$darkBlueTransparentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 18px;
}
.status_todo {
  color: #B24F48;
}
.status_done {
  color: #20A93F;
}