@use '@css/variables';
.projectWrapper {
  margin-top: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project {
  font-size: 24px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}
.nameWrapper {
  margin-top: 40px;
  min-width: 290px;
  height: 56px;
  display: flex;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
  border-radius: 4px;
  overflow: hidden;
}
.iconWrapper {
  width: 56px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(99deg, #1954EB -14.26%, #00A7FF 109.27%);
}
.icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
.name {
  margin-left: 17px;
  font-size: 18px;
}
.star {
  margin: 0 5px 0 auto;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: #ADA2C3;
}
.title, .news {
  font-size: 24px;
  color: #089EF0;
  font-weight: 500;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.title {
  margin-top: 56px;
}
.news {
  margin-top: 38px;
  margin-bottom: 22px;
}
.description {
  margin-top: 16px;
  width: 100%;
  min-height: 140px;
  padding: 14px 14px;
  color: #ADA2C3;
  border-radius: 4px;
  background: variables.$darkBlueTransparentColor;
}
