@use '@css/variables';
.wrapper {
  margin-top: 50px;
  width: 900px;
}
.line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
.container {
  padding: 33px 0;
  display: flex;
  align-items: center;
}
.firstNameWrapper {
  margin: 0 5px 0 24px;
  width: 700px;
}
.firstName {
  font-size: 24px;
}
.register {
  margin-top: 4px;
  color: #ADA2C3;
}
.description {
  margin-top: 9px;
  font-size: 20px;
  line-height: 1.5;
  color: #ADA2C3;
}
.link {
  margin: 0 0 auto auto;
}
.button {
  font-family: 'Rubik', sans-serif;
  min-width: 72px;
  color: #ADA2C3;
  padding: 12px;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.8px;
  border-radius: 4px;
  text-transform: uppercase;
  background: variables.$darkBlueTransparentColor;
}

