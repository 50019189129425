@use '@css/variables';
.td {
  padding-top: 32px;
}
.tdDiv, .tdName {
  height: 80px;
  background: variables.$darkBlueTransparentColor;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tdName {
  padding: 10px 10px 10px 32px;
  justify-content: flex-start;
}
.documentWrapper {
  padding: 6px 0;
  height: 100%;
  display: flex;
  border-radius: 4px;
}
.fileButton {
  padding: 0;
  margin-right: 5px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  overflow: hidden;
  :global(.react-pdf__Page__canvas) {
    width: 48px !important;
    height: 48px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
  &:hover {
    background: transparent;
    .fileBg {
      opacity: 1;
      transition: opacity ease .3s;
    }
  }
}
.fileBg {
  transition: opacity ease .3s;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 999;
  background: variables.$darkBlueTransparentColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.name {
  word-break: break-all;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
.downloadIcon {
  width: 28px;
  height: 28px;
}
.documentSize {
  margin-top: auto;
  font-family: 'Lato', sans-serif;
  font-size: 8px;
  color: #C7C6CA;
}
.currencyIcon {
  margin-left: 5px;
  width: 34px;
  height: 19px;
}