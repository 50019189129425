@use '@css/variables';
.wrapper {
  padding-top: 14px;
  width: 100%;
  min-height: 430px;
  max-height: 700px;
  overflow: hidden;
}
.line {
  width: 100%;
  height: 1px;
  background: linear-gradient(137.90deg, rgb(25, 84, 235) -17.982%,rgb(0, 167, 255) 117.266%)
}
.downLine {
  width: 2px;
  height: 100%;
  background: linear-gradient(137.90deg, rgb(25, 84, 235) -17.982%,rgb(0, 167, 255) 117.266%)
}
.requestContainer {
  width: 100%;
  height: 32px;
  display: flex;
}
.request {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #3E4954;
}
.dotsButton {
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  position: absolute;
  background: transparent;
}
.dotsIcon {
  font-size: 22px;
  color: #3E4954;
}
.status {
  top: 50%;
  right: 8px;
  position: absolute;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 2px solid #DADADA;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkIcon {
  color: #509B64;
  font-size: 10px;
}
.priorityIcon {
  color: #D80720;
  font-size: 10px;
}
.timeIcon {
  top: 50%;
  right: 8px;
  position: absolute;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  color: #DADADA;
}
.chat {
  padding: 15px 55px 35px 55px;
  overflow-y: auto;
  max-height: 655px;
}
.documentWrapper {
  margin-top: 17px;
  display: flex;
  align-items: center;
}
.documentWrapperMy {
  margin-left: auto;
}
.img {
  width: 100px;
  min-width: 100px;
  height: 70px;
  min-height: 70px;
  border-radius: 5px;
}
.document {
  order: 2;
  margin: 0 auto 0 32px;
  width: 100px !important;
  height: 70px !important;
  border-radius: 5px;
  overflow: hidden;
  :global(.react-pdf__Page__canvas) {
    width: 100px !important;
    height: 70px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
}
.fileLink {
  padding: 0;
}
.documentMy {
  order: 0;
  margin: 0 32px 0 auto;
}
.messageWrapper {
  margin-top: 35px;
  display: flex;
  align-items: flex-start;
}
.message, .myMessage {
  word-break: break-all;
  padding: 16px 24px;
  max-width: 475px;
  min-width: 120px;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 15px;
  }
}
.systemMessage {
  padding: 12px 15px 16px 10px;
  &::before {
    z-index: 2;
    left: -7px;
    border-top: 8px solid transparent;
    border-right: 7px solid #FEFEFE;
    border-bottom: 8px solid transparent;
  }
  &::after {
    z-index: 1;
    display: block;
    content: '';
    position: absolute;
    top: 15px;
    left: -8px;
    border-top: 8px solid transparent;
    border-right: 7px solid #1954EBFF;
    border-bottom: 8px solid transparent;
  }
  background: #FEFEFE;
  color: #777777;
}
.message {
  margin-left: 32px;
  background: #FEFEFE;
  color: #777777;
  &::before {
    left: -7px;
    border-top: 8px solid transparent;
    border-right: 7px solid #FEFEFE;
    border-bottom: 8px solid transparent;
  }
}
.myMessage {
  order: -1;
  margin: 0 32px 0 auto;
  background: #5185F7;
  color: #FEFEFE;
  &::before {
    right: -7px;
    border-top: 8px solid transparent;
    border-left: 7px solid #5185F7;
    border-bottom: 8px solid transparent;
  }
}