@use '@css/variables';
.wrapper {
  margin-left: auto;
  width: 288px;
  height: 56px;
  display: flex;
  position: relative;
}
.iconWrapper {
  z-index: 99;
  top: 0;
  left: 0;
  position: absolute;
  width: 56px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5637AD;
  border-radius: 4px 0 0 4px;
}
.icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
.select {
  width: 100%;
  padding-left: 56px;
  width: 288px;
  background: variables.$buttonBlueLinearGradient;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
  :global(.MuiSvgIcon-root) {
    color: #fff;
    font-size: 35px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  :global(.MuiList-root){
    background: variables.$darkBlueTransparentColor;
  }
  :global(.MuiSelect-select) {
    padding-right: 41px !important;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .selectIcon {
    display: none;
  }
}
.iconSelectWrapper {
  display: flex;
  align-items: center;
  height: 32px;
}
.selectIcon {
  margin-right: 2px;
  font-size: 32px;
  height: 32px;
  width: 32px;
}