@use '@css/variables';
.button {
  width: 248px;
  height: 56px;
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: 0.64px;
  background: variables.$buttonBlueLinearGradient;
  transition: opacity ease .3s;
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    background: variables.$buttonBlueHoverLinearGradient;
    opacity: 0;
    transition: opacity ease .3s;
  }
  &:hover::before {
    opacity: 1;
    transition: opacity ease .3s;
  }
  :global(.z-index-5-g) {
    display: flex;
    align-items: center;
  }
}
.button__small {
  width: 200px;
  height: 40px;
  font-size: 14px;
}





