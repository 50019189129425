@use '@css/variables';
.button {
  display: flex;
  align-items: center;
  color: #fff;
}
.iconWrapper {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  background: variables.$darkBlueTransparentColor;
  border-radius: 50%;
}
.icon {
  font-size: 32px;
}
.text {
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 28px;
  letter-spacing: 1.4px;
}





