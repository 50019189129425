@use '@css/variables';
.tbody {
  margin-top: 8px;
}
.usersWrapper {
  margin-right: 15px;
  max-width: 420px;
}
.td {
  padding: 32px 10px 0;
}
.tdDiv {
  height: 72px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: variables.$darkBlueTransparentColor;
  font-size: 18px;
}
.documentWrapper {
  padding: 15px 5px 8px 7px;
  width: 140px;
  height: 72px;
  display: flex;
  background: variables.$darkBlueTransparentColor;
  border-radius: 4px;
}
.fileButton {
  padding: 0;
  margin-right: 5px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  overflow: hidden;
  :global(.react-pdf__Page__canvas) {
    width: 48px !important;
    height: 48px !important;
  }
  :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
    display: none;
  }
  &:hover {
    background: transparent;
    .fileBg {
      opacity: 1;
      transition: opacity ease .3s;
    }
  }
}
.fileBg {
  transition: opacity ease .3s;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 999;
  background: variables.$darkBlueTransparentColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.name {
  word-break: break-all;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
.downloadIcon {
  width: 28px;
  height: 28px;
}
.documentSize {
  margin-top: auto;
  font-family: 'Lato', sans-serif;
  font-size: 8px;
  color: #C7C6CA;
}
.buttonWrapper {
  width: 40px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  width: 22px;
  height: 22px;
}