@use '@css/variables';
.tr {
  background: variables.$darkBlueTransparentColor;
  height: 56px;
}
.thDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.thButton {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow {
  margin-left: 4px;
  font-size: 25px;
}
.arrow_up {
  transform: rotateX(180deg);
}