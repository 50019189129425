@use '@css/variables';
.wrapper {
  padding: 28px 57px 38px 57px;
  width: 100%;
  background: variables.$darkBlueTransparentColor;
}
.titleWrapper {
  width: 100%;
  display: flex;
}
.title {
  font-size: 24px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}
.calendarWrapper {
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: center;
}
.tableWrapper {
  margin-top: 32px;
  width: 100%;
  min-height: 400px;
  position: relative;
}
.table {
  width: 100%;
}