@use '@css/variables';
.NDAButton {
  min-width: 120px;
  height: 48px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  background: rgba(54, 98, 195, 0.6);
  &:hover {
    background: rgba(36, 66, 136, 0.6);
  }
}
.modalWrapper {
  :global(.MuiDialog-container) {
    width: 100%;
  }
  :global(.MuiDialog-paper) {
    max-width: 464px;
    width: 100%;
    background: transparent;
  }
}
.form {
  padding: 32px 20px 26px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2000;
  min-height: 264px;
  background: #1E0E3F;
}
.title {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}
.fileWrapper {
  margin-top: 5px;
  display: flex;
  :global(.pdf-file-g) {
    margin-left: 12px;
    margin-right: 12px;
  }
}
.fileButton {
  margin: 20px 12px 0 12px;
  padding: 0;
  overflow: hidden;
  border-radius: 6px;
  background: transparent;
  width: 120px;
  height: 120px;
  :global(.react-pdf__Page__canvas) {
    width: 120px !important;
    height: 120px !important;
  }
  &:hover {
    background: transparent;
    .fileBg {
      opacity: 1;
      transition: opacity ease .3s;
    }
  }
}
.fileBg {
  transition: opacity ease .3s;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 999;
  background: variables.$darkBlueTransparentColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadIcon {
  width: 80px;
  height: 80px;
}
.buttonWrapper {
  margin: 25px auto 0;
  display: flex;
}
.acceptButton, .revokeButton {
  width: 120px;
  height: 32px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.acceptButton {
  margin: 0 8px;
  background: rgba(54, 98, 195, 0.6);
  &:hover {
    background: rgba(36, 66, 136, 0.6);
  }
}
.revokeButton {
  margin: 0 8px;
  background: rgba(221, 47, 110, 0.6);
  &:hover {
    background: rgba(154, 37, 78, 0.6);
  }
}
