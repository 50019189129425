@use '@css/variables';
.wrapper {
  margin-top: 22px;
  padding: 15px 14px 11px;
  width: 100%;
  max-width: 222px;
  height: 148px;
  background: variables.$darkBlueTransparentColor;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
}
.label {
  width: 96px;
  height: 20px;
  padding: 0 5px;
  background: variables.$buttonBlueLinearGradient;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  margin-top: 18px;
  font-size: 16px;
}
.dateWrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.date {
  font-weight: 300;
}
.status_todo {
  color: #B24F48;
}
.status_done {
  color: #20A93F;
}