@use '@css/variables';
.wrapper {
  margin-left: auto;
  padding: 14px 17px 45px;
  width: 360px;
  height: 513px;
  display: flex;
  flex-direction: column;
  background: variables.$darkBlueTransparentColor;
  border-radius: 10px;
}
.title {
  font-size: 20px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  text-align: left;
}
.avatarWrapper {
  margin: 22px auto 0;
  width: 144px;
  height: 144px;
}
.firstName {
  margin-top: 34px;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
}
.profession {
  margin-top: 8px;
  text-align: center;
  color: #557EF8;
}