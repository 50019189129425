@use '@css/variables';
.wrapper {
  background: linear-gradient(137.9deg, rgb(25, 84, 235) -17.982%, rgb(0, 167, 255) 117.266%);
  padding: 1px;
  border-radius: 5px;
  margin-left: 32px;
}
.messageWrapper {
  padding: 12px 16px 16px 14px;
  word-break: break-all;
  max-width: 475px;
  min-width: 120px;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 15px;
    z-index: 2;
    left: -7px;
    border-top: 8px solid transparent;
    border-right: 7px solid #FEFEFE;
    border-bottom: 8px solid transparent;
  }
  &::after {
    z-index: 1;
    display: block;
    content: '';
    position: absolute;
    top: 15px;
    left: -8px;
    border-top: 8px solid transparent;
    border-right: 7px solid #1954EBFF;
    border-bottom: 8px solid transparent;
  }
  background: #FEFEFE;
  color: #777777;
}
.message {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.timeIcon {
  margin-right: 5px;
  width: 26px;
  height: 26px;
  color: #1954EBFF;
}
.status {
  margin-right: 5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_created {
  border: 2px solid #509B64;
}
.status_canceled {
  border: 2px solid #D80720;
}
.checkIcon {
  color: #509B64;
  width: 16px;
  height: 16px;
}
.priorityIcon {
  color: #D80720;
  width: 16px;
  height: 16px;
}
.text {
  word-break: break-all;
  font-family: 'Lato', sans-serif;
  color: #777777;
}
.fileButton {
  margin-left: 15px;
  width: 40px;
  min-width: 40px;
  position: relative;
}
.fileIcon {
  color: #F05640;
}
.addFileButton {
  margin-left: 10px;
}
.addFileIcon {
  font-size: 24px;
  color: #777777;
}
.comment {
  word-break: break-all;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #1e2428;
}